import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import HeaderSection from '../components/section-header'
import Section from '../components/section'
import { Typography, Grid } from '@material-ui/core'
import styled from '@emotion/styled'

const Intro = styled(Typography)`
  font-size: 1.5rem;
  font-family: Produkt,serif;
`

const Label = styled(Typography)`
  font-style: normal;
`

const A = styled.a`
  color: #4f5cee;
`

export default ({ data }) => {
  const { frontmatter } = data.markdownRemark
  
  return (
    <Layout>
      <HeaderSection {...frontmatter.header} />
      <Section>
        <Intro variant="body1">{frontmatter.intro}</Intro>
      </Section>
      <Section>
        <Grid container>
          <Grid item>
            <Label variant="h6">Email Us</Label>
            <Typography variant="h2"><A href="mailto:info@buildxlab.com">info@buildxlab.com</A></Typography>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query ContactPageQuery($id: String!) {
    markdownRemark(id: {eq: $id }) {
      frontmatter {
        header {
          title
          backgroundColor
          image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        title
        intro
        image_and_text {
          image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          text
        }
      }
      html
    }
  }
`